* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #0F172A;
  color: #E2E8F0;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.cursor {
  z-index: 10;
  position: fixed;
  width: 550px;
  height: 550px;
  border-radius: 50%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(29, 79, 216, 0.244) 5%, transparent 99%);
  box-shadow: 0 0 150px 55px rgba(29, 79, 216, 0.070),
              0 0 150px 70px rgba(29, 79, 216, 0.040);
  transform: translate(-272px, -268px);
}

.main {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  gap: 50px;
}

.main .leftside {
  position: sticky;
  z-index: 11;
  width: 550px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 100px;
}

.main .leftside h1 {
  font-size: 46px;
}

.main .leftside h3 {
  font-weight: 400;
}

.main .leftside p {
  width: 300px;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #e2e8f0a1;
}

.main .leftside .links {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main .leftside .links a {
  text-decoration: none;
  color: #ffffff88;
  font-size: 16px;
  text-transform: uppercase;
  transition: color, transform 500ms ease-out;
}

.main .leftside .links a:hover {
  /* color: #E2E8F0; */
  transform: scale(1.01);
}

.socials {
  display: flex;
  gap: 22px;
  margin-top: 290px;
  font-size: 32px;
}
.socials a {
  text-decoration: none;
  color: #ffffff88;
}
.socials a:hover {
  color: #E2E8F0;
}

.main .rightside {
  z-index: 12;
  width: 500px;
  margin-top: 20px;
}

.main .rightside .r_about {
  display: none;
}

.main .rightside p {
  font-weight: 300;
  font-style: normal;
  color: #e2e8f0a1;
}

.main .rightside p b {
  color: #E2E8F0;
}

.tglink {
  text-decoration: none;
  color: #E2E8F0;
}

.tglink:hover {
  color: rgb(94 234 212);
}

.experience {
  width: 480px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 100px;
}

.exp_icons {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  font-size: 74px;
  margin-top: 25px;
}

.projects {
  margin-top: 80px;
}

.myprojects {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.pr_one {
  width: 90%;
  display: flex;
  gap: 10px;
}

.pr_one img {
  width: 140px;
  height: 125px;
  object-fit: cover;
  border-radius: 5px;
}

.pr_one a {
  text-decoration: none;
  color: #E2E8F0;
}

.pr_one a:hover {
  color: rgb(94 234 212);
}

.info {
  max-width: calc(100% - 160px);
}

.linkprojects {
  margin: 40px 0 80px 0;
}

.linkprojects a {
  text-decoration: none;
  color: #E2E8F0;
}

.linkprojects a:hover {
  color: rgb(94 234 212);
}

.footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.footer a {
  text-decoration: none;
  color: #E2E8F0;
}
.footer a:hover {
  color: rgb(94 234 212);
}


/* responsive !!! */

/* 1200 */
@media only screen and (max-width: 1200px) {
  .main {
    gap: 30px;
  }
  .main .leftside {
    width: 500px;
  }
  .main .rightside {
    width: 450px;
  }
}

/* 1090 */
@media only screen and (max-width: 1090px) {
  .main {
    gap: 25px;
  }
  .main .leftside {
    width: 450px;
  }
  .main .rightside {
    width: 400px;
  }
  .experience {
    width: 440px;
  }
}

/* 992 */
@media only screen and (max-width: 992px) {
  .main {
    gap: 14px;
  }
  .main .leftside {
    width: 440px;
  }
  .main .rightside {
    width: 390px;
  }
  .experience {
    width: 420px;
  }
}

/* 786 */
@media only screen and (max-width: 786px) {
  .main {
    width: 100%;
    gap: 10px;
  }
  .main .leftside {
    width: 360px;
  }
  .main .leftside h1 {
    font-size: 40px;
  }
  .main .leftside .links a {
    font-size: 14px;
  }
  .socials {
    font-size: 30px;
  }
  .pr_one {
    width: 95%;
  }
  .pr_one img {
    width: 120px;
    height: 115px;
  }
  .pr_one a {
    font-size: 14px;
  }
  .pr_one .info p {
    font-size: 15px;
  }
  .main .rightside {
    width: 350px;
  }
  .main .rightside p {
    font-weight: 250;
  }
  .experience {
    width: 400px;
  }
  .exp_icons {
    font-size: 66px;
  }
}

/* 768 */
@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
    gap: 2px;
    margin-top: 70px;
  }
  .main .leftside {
    width: 350px;
    top: 70px;
  }
  .main .leftside h1 {
    font-size: 38px;
  }
  .socials {
    font-size: 28px;
  }
  .pr_one img {
    width: 100px;
    height: 90px;
  }
  .pr_one a {
    font-size: 12px;
  }
  .pr_one .info p {
    font-size: 16px;
  }
  .main .rightside {
    width: 350px;
  }
  .exp_icons {
    font-size: 62px;
  }
}

/* 576 */
@media only screen and (max-width: 576px) {
  .main {
    width: 100%;
    flex-direction: column;
    margin-top: 10px;
  }
  .main .leftside {
    position: relative;
    width: 350px;
    top: 20px;
    left: 30px;
  }
  .main .leftside .links {
    display: none;
  }
  .main .leftside h1 {
    font-size: 40px;
  }
  .socials {
    margin-top: 30px;
    margin-bottom: 100px;
    font-size: 28px;
  }
  .main .rightside {
    position: relative;
    width: 480px;
    left: 30px;
  }
  .main .rightside h2 {
    margin-bottom: 22px;
  }
  .main .rightside p {
    font-weight: 300;
  }
  .main .rightside .r_about {
    display: block;
  }
  .experience {
    width: 480px;
  }
  .exp_icons {
    font-size: 74px;
  }
  .pr_one {
    width: 95%;
  }
  .pr_one img {
    width: 130px;
    height: 115px;
  }
  .pr_one a {
    font-size: 14px;
  }

}

/* iphone xr (414) */
@media only screen and (max-width: 414px) {
  .main {
    width: 100%;
  }
  .main .leftside h1 {
    font-size: 46px;
  }
  .main .leftside p {
    width: 100%;
    font-size: 20px;
  }
  .main .leftside h3 {
    font-size: 22px;
  }
  .socials {
    font-size: 32px;
  }
  .main .rightside {
    position: relative;
    width: 414px;
    left: 30px;
  }
  .main .rightside h2 {
    margin-bottom: 22px;
  }
  .main .rightside p {
    width: 95%;
    font-weight: 300;
    font-size: 20px;
  }
  .experience {
    width: 414px;
  }
  .exp_icons {
    font-size: 68px;
  }
}

/* 376 */
@media only screen and (max-width: 376px) {
  .socials {
    font-size: 34px;
  }
  .main .rightside {
    width: 376px;
  }
  .main .rightside p {
    font-size: 18px;
  }
  .main .info p {
    font-size: 16px;
  }
  .pr_one {
    width: 376px;
  }
  .pr_one img {
    width: 110px;
    height: 95px;
  }
  .experience {
    width: 376px;
  }
}
